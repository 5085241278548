// @flow

import React from 'react'
import type { Node } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { CreateListing } from '../../scenes/listing'

import { getFormattedData } from '../../helpers'
import { mermaidOverview } from '../../data/cottages-content/mermaid'

import type {
  CottagePageContent,
  LegacyImage,
  OverviewItem,
  Image,
} from '../../types'

type QueryResponse = {|
  mermaidCottagePage: CottagePageContent,
|}

type MermaidCottagePagePureProps = {
  data: {
    overview: OverviewItem[],
    cottageImages: LegacyImage[],
    heroImage: Image,
  },
}

function MermaidCottagePagePure({
  data: { overview, cottageImages, heroImage },
}: MermaidCottagePagePureProps) {
  return (
    <CreateListing
      id="mermaid-cottage"
      cottageName="Mermaid"
      featuredImageUrl={heroImage.src}
      overview={overview}
      welcomeMessage="Welcome to the Mermaid Cottage"
      images={cottageImages}
    />
  )
}

function MermaidCottagePage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query MermaidCottagePageQuery {
      mermaidCottagePage: contentfulCottagePage(title: { eq: "Mermaid" }) {
        id
        title
        featuredImage {
          id
          title
          description
          image: resize(width: 1800, quality: 80) {
            src
          }
        }
        cottageImages {
          id
          description
          image: resize(width: 800, quality: 80) {
            src
          }
        }
      }
    }
  `)

  return (
    <MermaidCottagePagePure
      data={{
        ...getFormattedData(data.mermaidCottagePage),
        overview: mermaidOverview,
      }}
    />
  )
}

export default MermaidCottagePage
