// @flow

import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types'

const overview = [
  {
    title: 'Bright and Fresh',
    message:
      'Bright and fresh colors are the hallmark of the professionally decorated Mermaid Cottage.',
  },
  {
    title: 'Gorgeous Bathroom',
    message: 'The master bedroom has a sitting area and en suite bath.',
  },
  {
    title: 'Room Configuration',
    message: '2 Bedroom (1 K, 2 T) 2 Bath.',
  },
]

export const mermaidOverview: OverviewItem[] = map(
  generateIdFromTitle,
  overview,
)

export default mermaidOverview
